<template>
  <el-card shadow="never" style="height:100%">
    <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
    >
      <el-form-item label="类别名称" prop="name">
        <el-input v-model="ruleForm.name" autocomplete="off" style="width: 250px"></el-input>
        <el-button type="primary" @click="addTypeBig('ruleForm')" style="background: #1d90ff; border:none;margin-left: 20px" :disabled="this.show">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import $ from "jquery";

export default {
  data() {
    return {
       show:false,
       rules: {
        name: [
          {required: true, message: "请输入类别名称", trigger: "blur"},
        ],
      },
      ruleForm: {
        id: "",
      },
      
    };
  },
  methods: {
    
    addTypeBig(ruleForm) {
      this.show = true;
      // console.log(this.$refs[ruleForm]);
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$api.post(
          "/type/big/save",
          null,
          {name: this.ruleForm.name},
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              
              this.$router.push({name: "masterList"});
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
          
        } else {
          console.log("error submit!!");
          this.$message("输入数据不正确！");
          return false;
        }
      });
    }
  },
  
};
</script>